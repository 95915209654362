export default [
  {
    path: "/",
    name: "home",
    component: () => import("modules/home"),
  },
  {
    path: "/survey",
    name: "survey",
    component: () => import("modules/survey"),
  },
  {
    path: "/company/survey",
    name: "company",
    component: () => import("modules/company"),
  },
  {
    path: "/basic/survey",
    name: "basic",
    component: () => import("modules/basic"),
  },
];
